/**
=========================================================
* ControlWorks - v0.1.0
=========================================================

* Product Page: https://controlworksbas.com/
* Copyright 2024 ControlWorks BAS

 =========================================================

*/


// Material Kit 2 React pages
import Services from "../../../pages/Services";

export default function ServicesPage() {
  return <Services />;
}
